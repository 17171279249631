body {
  font-family: sans-serif;
  font-style: normal;
  background-color: #f9f5f4;
}
.air-quality-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  background-color: #f5f5f5;
  min-height: 100vh;
  margin: 0;
}

.header {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  font-family: sans-serif;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.aqi-value {
  font-size: 50px;
  font-weight: bold;
}

.pollution-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.pollution-detail {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.pollution-detail p {
  font-size: 20px;
  margin-bottom: 5px;
}

.pollution-detail span {
  font-size: 16px;
}
